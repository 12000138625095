@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

* {
  font-family: 'Inter', sans-serif !important;
}

/* Tailwindcss conflicts with Angular Material css class mdc-notched-outline__notch. border-style: solid rule is defined in Tailwindcss base.css file.
 https://stackoverflow.com/a/76034412 */
.mdc-notched-outline__notch
{
  border-right: none;
}

p {
  margin: 0 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

#custom-datepicker-toogle-id > button > span > svg {
  width: 24px;
  height: 24px;
}

.mat-calendar-body-selected {
  background-color: #136c4c;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-no-padding .mat-form-field-infix {
  padding: 1em 0 0.9em 0;
  border: 0px;
}

.mat-mdc-progress-spinner{
  --mdc-circular-progress-active-indicator-color: #136c4c;
}